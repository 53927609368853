<template>
  <v-navigation-drawer
    v-model="state"
    style="background-color: #ffffff"
    app
    light
    right
    width="450px"
  >
    <v-toolbar v-if="editDetails" color="primary" class="elevation-1">
      <v-toolbar-title
        ><span
          class=" text-uppercase font white--text font-weight-bold font-size-md"
          >Edit {{ editDetails.name }} Details</span
        ></v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click.stop="close" v-on="on" icon color="white">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
        <span class="ft font-weight-medium">{{ "Close" }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-divider></v-divider>
    <v-card flat :disabled="detailsLoading" :loading="detailsLoading">
      <v-card-text>
        <ValidationObserver
          class="d-flex flex-column flex-grow-1"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(updateCustomer)">
            <ValidationProvider
              tag="div"
              class="font d-flex flex-row flex-grow-1"
              rules="required"
              v-slot="{ errors }"
            >
              <i
                :class="errors.length > 0 ? 'red--text' : 'black--text'"
                class="material-icons-outlined mt-4 mr-3"
                >verified_user</i
              >
              <v-text-field
                autofocus
                class="font font-weight-medium d-flex flex-row flex-grow-1"
                label="Business Registration Number"
                clearable
                :error-messages="errors[0]"
                v-model="editDetails.businessRegistrationNumber"
                hint="Enter registration no. here"
              />
            </ValidationProvider>
            <div class="d-flex flex-row flex-grow-1">
              <ValidationProvider
                tag="div"
                class="font d-flex flex-row flex-grow-1"
                rules="required"
                v-slot="{ errors }"
              >
                <i
                  :class="errors.length > 0 ? 'red--text' : 'black--text'"
                  class="material-icons-outlined mt-4 mr-3"
                  >store</i
                >
                <v-text-field
                  autofocus
                  class="font font-weight-medium d-flex flex-row flex-grow-1"
                  label="Business Name"
                  clearable
                  :error-messages="errors[0]"
                  v-model="editDetails.name"
                  hint="Enter business name here"
                />
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="font d-flex flex-row flex-grow-1"
                rules="required|email"
                v-slot="{ errors }"
              >
                <i
                  :class="errors.length > 0 ? 'red--text' : 'black--text'"
                  class="material-icons-outlined mt-4 mr-3"
                  >email</i
                >
                <v-text-field
                  class="font font-weight-medium d-flex flex-row flex-grow-1"
                  label="Business Email"
                  clearable
                  :error-messages="errors[0]"
                  v-model="editDetails.email"
                  hint="Enter business email here"
                />
              </ValidationProvider>
            </div>

            <div class="d-flex flex-row flex-grow-1">
              <ValidationProvider
                tag="div"
                class="font d-flex flex-row flex-grow-1"
                rules="required|numeric|max:12"
                v-slot="{ errors }"
              >
                <i
                  :class="errors.length > 0 ? 'red--text' : 'black--text'"
                  class="material-icons-outlined mt-4 mr-3"
                  >phone</i
                >
                <v-text-field
                  class="font font-weight-medium d-flex flex-row flex-grow-1"
                  label="Business Contact"
                  clearable
                  :error-messages="errors[0]"
                  v-model="editDetails.contact"
                  hint="Enter business contact here"
                /> </ValidationProvider
              ><ValidationProvider
                tag="div"
                class="font d-flex flex-row flex-grow-1"
                rules="required"
                v-slot="{ errors }"
              >
                <i
                  :class="errors.length > 0 ? 'red--text' : 'black--text'"
                  class="material-icons-outlined mt-4 mr-3"
                  >map</i
                >
                <v-text-field
                  class="font font-weight-medium d-flex flex-row flex-grow-1"
                  label="Business Address"
                  clearable
                  :error-messages="errors[0]"
                  v-model="editDetails.address"
                  hint="Enter address here"
                />
              </ValidationProvider>
            </div>
            <div class="d-flex flex-row flex-grow-1">
              <ValidationProvider
                tag="div"
                class="font d-flex flex-row flex-grow-1"
                rules="required"
                v-slot="{ errors }"
              >
                <i
                  :class="errors.length > 0 ? 'red--text' : 'black--text'"
                  class="material-icons-outlined mt-4 mr-3"
                  >location_on</i
                >
                <v-text-field
                  v-model="editDetails.officeLocation"
                  class="font font-weight-medium d-flex flex-row flex-grow-1"
                  label="Office Location"
                  clearable
                  :error-messages="errors[0]"
                  hint="Enter office location here"
                />
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="font d-flex flex-row flex-grow-1"
                rules="required"
                v-slot="{ errors }"
              >
                <i
                  :class="errors.length > 0 ? 'red--text' : 'black--text'"
                  class="material-icons-outlined mt-4 mr-3"
                  >credit_card</i
                >
                <v-text-field
                  v-model="editDetails.tin"
                  class="font font-weight-medium d-flex flex-row flex-grow-1"
                  label="Business TIN"
                  clearable
                  :error-messages="errors[0]"
                  hint="Enter business tin here"
                />
              </ValidationProvider>
            </div>
            <div class="d-flex flex-row flex-grow-1 mt-3">
              <ValidationProvider
                tag="div"
                class="font d-flex flex-row flex-grow-1"
                rules="required"
                v-slot="{ errors }"
              >
                <i
                  :class="errors.length > 0 ? 'red--text' : 'black--text'"
                  class="material-icons-outlined mt-4 mr-3"
                  >phone</i
                >
                <v-autocomplete
                  :items="categoryList"
                  class="  font font-weight-medium font-size-sm"
                  v-model="editDetails.category"
                  label="Supplier Category"
                  item-text="name"
                  hint="Choose Supplier Category ..."
                />
              </ValidationProvider>
            </div>
            <div
              v-if="editDetails.directors"
              class="d-flex flex-column flex-grow-1 pa-2"
            >
              <h3 class="font font-weight-bold font-size-md py-1">
                Add Board Members OR Directors
              </h3>
              <div
                v-for="(director, index) in editDetails.directors"
                :key="index"
                style="border-radius: 8px; background: rgb(232 232 232 / 41%);"
                class="d-flex flex-row flex-grow-1 px-4 my-1"
              >
                <i class="material-icons-outlined mt-4 mr-3">account_circle</i>
                <ValidationProvider
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                  class="d-flex flex-row flex-grow-1"
                >
                  <v-text-field
                    class="font font-weight-medium"
                    label="Full Name"
                    :error-messages="errors[0]"
                    hint="Enter directors' full name here ..."
                    v-model="director.name"
                  />
                </ValidationProvider>
                <ValidationProvider
                  rules="required|email"
                  tag="div"
                  v-slot="{ errors }"
                  class="d-flex flex-row flex-grow-1"
                >
                  <i
                    :class="errors.length > 0 ? 'red--text' : 'black--text'"
                    class="material-icons-outlined mt-4 mr-3"
                    >email</i
                  >
                  <v-text-field
                    class="font font-weight-medium"
                    label="Email Address"
                    :error-messages="errors[0]"
                    hint="Enter directors' email address here ..."
                    v-model="director.email"
                  />
                </ValidationProvider>
                <ValidationProvider
                  rules="required|numeric|max:12"
                  tag="div"
                  v-slot="{ errors }"
                  class="d-flex flex-row flex-grow-1"
                >
                  <i
                    :class="errors.length > 0 ? 'red--text' : 'black--text'"
                    class="material-icons-outlined mt-4 mr-3"
                    >phone</i
                  >
                  <v-text-field
                    class="font font-weight-medium"
                    label="Phone Number"
                    :error-messages="errors[0]"
                    hint="Enter directors' phone here ..."
                    v-model="director.phone"
                  />
                </ValidationProvider>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="editDetails.directors.length > 1"
                      small
                      v-on="on"
                      absolute
                      @click="removeRow(index)"
                      text
                      fab
                      right
                      color="error"
                      class="mt-3 mr-n4"
                      style="z-index: 10"
                    >
                      <i class="material-icons-outlined font-size-md">delete</i>
                    </v-btn>
                  </template>
                  <span class="font font-size-sm">Remove Director</span>
                </v-tooltip>
              </div>
              <div class="d-flex flex-row flex-grow-1 justify-end mt-2">
                <v-btn @click="addRow" small color="primary" text outlined>
                  <span class="font font-weight-medium">Add New Row</span>
                </v-btn>
              </div>
            </div>
            <span
              v-if="editDetails.contactPerson"
              class="text-uppercase font font-weight-bold font-size-sm mt-4"
              >Details of a contact person</span
            >
            <div
              v-if="editDetails.contactPerson"
              class="d-flex flex-row flex-grow-1"
            >
              <div
                style="border-radius: 8px; background: rgb(232 232 232 / 41%);"
                class="d-flex flex-row flex-grow-1 px-4 my-1"
              >
                <i class="material-icons-outlined mt-4 mr-3">account_circle</i>
                <ValidationProvider
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                  class="d-flex flex-row flex-grow-1"
                >
                  <v-text-field
                    class="font font-weight-medium"
                    label="Name"
                    :error-messages="errors[0]"
                    hint="Enter directors' full name here ..."
                    v-model="editDetails.contactPerson.name"
                  />
                </ValidationProvider>
                <ValidationProvider
                  rules="required|email"
                  tag="div"
                  v-slot="{ errors }"
                  class="d-flex flex-row flex-grow-1"
                >
                  <i
                    :class="errors.length > 0 ? 'red--text' : 'black--text'"
                    class="material-icons-outlined mt-4 mr-3"
                    >email</i
                  >
                  <v-text-field
                    class="font font-weight-medium"
                    label="Email Address"
                    :error-messages="errors[0]"
                    hint="Enter directors' email address here ..."
                    v-model="editDetails.contactPerson.email"
                  />
                </ValidationProvider>
                <ValidationProvider
                  rules="required|numeric|max:12"
                  tag="div"
                  v-slot="{ errors }"
                  class="d-flex flex-row flex-grow-1"
                >
                  <i
                    :class="errors.length > 0 ? 'red--text' : 'black--text'"
                    class="material-icons-outlined mt-4 mr-3"
                    >phone</i
                  >
                  <v-text-field
                    class="font font-weight-medium"
                    label="Phone Number"
                    :error-messages="errors[0]"
                    hint="Enter directors' phone here ..."
                    v-model="editDetails.contactPerson.phone"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div class="d-flex flex-row flex-grow-1">
              <ValidationProvider
                v-if="editDetails && editDetails.natureOfBusiness"
                rules="required"
                tag="div"
                v-slot="{ errors }"
                class="d-flex flex-row flex-grow-1"
              >
                <i
                  :class="errors.length > 0 ? 'red--text' : 'black--text'"
                  class="material-icons-outlined mt-4 mr-3"
                  >store</i
                >
                <v-text-field
                  class="font font-weight-medium"
                  label="Nature Of Business"
                  :error-messages="errors[0]"
                  hint="Enter company's nature of business here ..."
                  v-model="editDetails.natureOfBusiness"
                />
              </ValidationProvider>
              <ValidationProvider
                v-else
                rules="required"
                tag="div"
                v-slot="{ errors }"
                class="d-flex flex-row flex-grow-1"
              >
                <i
                  :class="errors.length > 0 ? 'red--text' : 'black--text'"
                  class="material-icons-outlined mt-4 mr-3"
                  >store</i
                >
                <v-text-field
                  class="font font-weight-medium"
                  label="Area Of Trade Or Supply"
                  :error-messages="errors[0]"
                  hint="Enter company's area of trade here ..."
                  v-model="editDetails.areaOfTradeOrSupply"
                />
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="font d-flex flex-row flex-grow-1"
                rules="required"
                v-slot="{ errors }"
              >
                <i
                  :class="errors.length > 0 ? 'red--text' : 'black--text'"
                  class="material-icons-outlined mt-4 mr-3"
                  >credit_card</i
                >
                <v-text-field
                  v-model="editDetails.amount"
                  class="font font-weight-medium d-flex flex-row flex-grow-1"
                  label="Bal/Amount"
                  clearable
                  :error-messages="errors[0]"
                  hint="Enter business balance or amount"
                />
              </ValidationProvider>
            </div>
            <div class="d-flex flex-column flex-grow-1">
              <v-btn
                type="submit"
                color="primary"
                :disabled="loading"
                :loading="loading"
                class="mx-2 mt-3"
              >
                <span class="font font-weight-medium">Save Details</span>
              </v-btn>
            </div>
          </form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { email, max, min, numeric, required } from "vee-validate/dist/rules";
import { cloneDeep } from "lodash";
extend("required", {
  ...required,
  message: "Input field is required"
});
extend("email", {
  ...email,
  message: "Input must be a valid email address"
});
extend("max", {
  ...max,
  message: "Invalid length provided"
});
extend("min", {
  ...min,
  message: "Invalid length provided"
});
extend("numeric", {
  ...numeric,
  message: `Input must contain only numbers`
});
export default {
  name: "DetailsDrawerDialog",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    state: Boolean,
    loading: Boolean,
    title: {
      type: String,
      default: "Details"
    },
    details: {
      type: Object,
      default: null
    },
    currency: {
      type: Array,
      default: () => []
    },
    detailsLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    pdf: null,
    editDetails: {},
    categoryList: ["Standard", "Flat"]
  }),
  watch: {
    details(payload) {
      let details = cloneDeep(payload);
      this.editDetails = {
        ...details,
        ...(details.creditPurchasesAmount
          ? {
              amount: details.creditPurchasesAmount
            }
          : { amount: 0 })
      };
    }
  },
  methods: {
    addRow() {},
    updateCustomer() {
      if (isNaN(Number(this.editDetails.amount))) {
        this.$store.dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Invalid input provided for amount`,
            status: "red"
          },
          { root: true }
        );
      } else {
        this.$emit("updateCustomer", {
          ...this.editDetails,
          creditPurchasesAmount: Number(this.editDetails.amount)
        });
      }
    },
    openMail(email) {
      this.$emit("openMail", { email });
    },
    close() {
      this.$emit("close", "edit");
    }
  }
};
</script>
