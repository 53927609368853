var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticStyle:{"background-color":"#ffffff"},attrs:{"app":"","light":"","right":"","width":"450px"},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[(_vm.editDetails)?_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"primary"}},[_c('v-toolbar-title',[_c('span',{staticClass:" text-uppercase font white--text font-weight-bold font-size-md"},[_vm._v("Edit "+_vm._s(_vm.editDetails.name)+" Details")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.close($event)}}},on),[_c('v-icon',[_vm._v("close")])],1)]}}],null,false,645772943)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(_vm._s("Close"))])])],1):_vm._e(),_c('v-divider'),_c('v-card',{attrs:{"flat":"","disabled":_vm.detailsLoading,"loading":_vm.detailsLoading}},[_c('v-card-text',[_c('ValidationObserver',{staticClass:"d-flex flex-column flex-grow-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateCustomer)}}},[_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("verified_user")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"autofocus":"","label":"Business Registration Number","clearable":"","error-messages":errors[0],"hint":"Enter registration no. here"},model:{value:(_vm.editDetails.businessRegistrationNumber),callback:function ($$v) {_vm.$set(_vm.editDetails, "businessRegistrationNumber", $$v)},expression:"editDetails.businessRegistrationNumber"}})]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("store")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"autofocus":"","label":"Business Name","clearable":"","error-messages":errors[0],"hint":"Enter business name here"},model:{value:(_vm.editDetails.name),callback:function ($$v) {_vm.$set(_vm.editDetails, "name", $$v)},expression:"editDetails.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("email")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"label":"Business Email","clearable":"","error-messages":errors[0],"hint":"Enter business email here"},model:{value:(_vm.editDetails.email),callback:function ($$v) {_vm.$set(_vm.editDetails, "email", $$v)},expression:"editDetails.email"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|numeric|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("phone")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"label":"Business Contact","clearable":"","error-messages":errors[0],"hint":"Enter business contact here"},model:{value:(_vm.editDetails.contact),callback:function ($$v) {_vm.$set(_vm.editDetails, "contact", $$v)},expression:"editDetails.contact"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("map")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"label":"Business Address","clearable":"","error-messages":errors[0],"hint":"Enter address here"},model:{value:(_vm.editDetails.address),callback:function ($$v) {_vm.$set(_vm.editDetails, "address", $$v)},expression:"editDetails.address"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("location_on")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"label":"Office Location","clearable":"","error-messages":errors[0],"hint":"Enter office location here"},model:{value:(_vm.editDetails.officeLocation),callback:function ($$v) {_vm.$set(_vm.editDetails, "officeLocation", $$v)},expression:"editDetails.officeLocation"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("credit_card")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"label":"Business TIN","clearable":"","error-messages":errors[0],"hint":"Enter business tin here"},model:{value:(_vm.editDetails.tin),callback:function ($$v) {_vm.$set(_vm.editDetails, "tin", $$v)},expression:"editDetails.tin"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-3"},[_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("phone")]),_c('v-autocomplete',{staticClass:"  font font-weight-medium font-size-sm",attrs:{"items":_vm.categoryList,"label":"Supplier Category","item-text":"name","hint":"Choose Supplier Category ..."},model:{value:(_vm.editDetails.category),callback:function ($$v) {_vm.$set(_vm.editDetails, "category", $$v)},expression:"editDetails.category"}})]}}],null,true)})],1),(_vm.editDetails.directors)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 pa-2"},[_c('h3',{staticClass:"font font-weight-bold font-size-md py-1"},[_vm._v(" Add Board Members OR Directors ")]),_vm._l((_vm.editDetails.directors),function(director,index){return _c('div',{key:index,staticClass:"d-flex flex-row flex-grow-1 px-4 my-1",staticStyle:{"border-radius":"8px","background":"rgb(232 232 232 / 41%)"}},[_c('i',{staticClass:"material-icons-outlined mt-4 mr-3"},[_vm._v("account_circle")]),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium",attrs:{"label":"Full Name","error-messages":errors[0],"hint":"Enter directors' full name here ..."},model:{value:(director.name),callback:function ($$v) {_vm.$set(director, "name", $$v)},expression:"director.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("email")]),_c('v-text-field',{staticClass:"font font-weight-medium",attrs:{"label":"Email Address","error-messages":errors[0],"hint":"Enter directors' email address here ..."},model:{value:(director.email),callback:function ($$v) {_vm.$set(director, "email", $$v)},expression:"director.email"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"rules":"required|numeric|max:12","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("phone")]),_c('v-text-field',{staticClass:"font font-weight-medium",attrs:{"label":"Phone Number","error-messages":errors[0],"hint":"Enter directors' phone here ..."},model:{value:(director.phone),callback:function ($$v) {_vm.$set(director, "phone", $$v)},expression:"director.phone"}})]}}],null,true)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.editDetails.directors.length > 1)?_c('v-btn',_vm._g({staticClass:"mt-3 mr-n4",staticStyle:{"z-index":"10"},attrs:{"small":"","absolute":"","text":"","fab":"","right":"","color":"error"},on:{"click":function($event){return _vm.removeRow(index)}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-md"},[_vm._v("delete")])]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"font font-size-sm"},[_vm._v("Remove Director")])])],1)}),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end mt-2"},[_c('v-btn',{attrs:{"small":"","color":"primary","text":"","outlined":""},on:{"click":_vm.addRow}},[_c('span',{staticClass:"font font-weight-medium"},[_vm._v("Add New Row")])])],1)],2):_vm._e(),(_vm.editDetails.contactPerson)?_c('span',{staticClass:"text-uppercase font font-weight-bold font-size-sm mt-4"},[_vm._v("Details of a contact person")]):_vm._e(),(_vm.editDetails.contactPerson)?_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 px-4 my-1",staticStyle:{"border-radius":"8px","background":"rgb(232 232 232 / 41%)"}},[_c('i',{staticClass:"material-icons-outlined mt-4 mr-3"},[_vm._v("account_circle")]),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium",attrs:{"label":"Name","error-messages":errors[0],"hint":"Enter directors' full name here ..."},model:{value:(_vm.editDetails.contactPerson.name),callback:function ($$v) {_vm.$set(_vm.editDetails.contactPerson, "name", $$v)},expression:"editDetails.contactPerson.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("email")]),_c('v-text-field',{staticClass:"font font-weight-medium",attrs:{"label":"Email Address","error-messages":errors[0],"hint":"Enter directors' email address here ..."},model:{value:(_vm.editDetails.contactPerson.email),callback:function ($$v) {_vm.$set(_vm.editDetails.contactPerson, "email", $$v)},expression:"editDetails.contactPerson.email"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"rules":"required|numeric|max:12","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("phone")]),_c('v-text-field',{staticClass:"font font-weight-medium",attrs:{"label":"Phone Number","error-messages":errors[0],"hint":"Enter directors' phone here ..."},model:{value:(_vm.editDetails.contactPerson.phone),callback:function ($$v) {_vm.$set(_vm.editDetails.contactPerson, "phone", $$v)},expression:"editDetails.contactPerson.phone"}})]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[(_vm.editDetails && _vm.editDetails.natureOfBusiness)?_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("store")]),_c('v-text-field',{staticClass:"font font-weight-medium",attrs:{"label":"Nature Of Business","error-messages":errors[0],"hint":"Enter company's nature of business here ..."},model:{value:(_vm.editDetails.natureOfBusiness),callback:function ($$v) {_vm.$set(_vm.editDetails, "natureOfBusiness", $$v)},expression:"editDetails.natureOfBusiness"}})]}}],null,true)}):_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("store")]),_c('v-text-field',{staticClass:"font font-weight-medium",attrs:{"label":"Area Of Trade Or Supply","error-messages":errors[0],"hint":"Enter company's area of trade here ..."},model:{value:(_vm.editDetails.areaOfTradeOrSupply),callback:function ($$v) {_vm.$set(_vm.editDetails, "areaOfTradeOrSupply", $$v)},expression:"editDetails.areaOfTradeOrSupply"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"font d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mt-4 mr-3",class:errors.length > 0 ? 'red--text' : 'black--text'},[_vm._v("credit_card")]),_c('v-text-field',{staticClass:"font font-weight-medium d-flex flex-row flex-grow-1",attrs:{"label":"Bal/Amount","clearable":"","error-messages":errors[0],"hint":"Enter business balance or amount"},model:{value:(_vm.editDetails.amount),callback:function ($$v) {_vm.$set(_vm.editDetails, "amount", $$v)},expression:"editDetails.amount"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-btn',{staticClass:"mx-2 mt-3",attrs:{"type":"submit","color":"primary","disabled":_vm.loading,"loading":_vm.loading}},[_c('span',{staticClass:"font font-weight-medium"},[_vm._v("Save Details")])])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }